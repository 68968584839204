import axios from '@/axios.js'

const state = () => ({
    menus: [],
})

const mutations = {
  SET_MENUS (state, payload) {
    state.menus = payload
  },
  ADD_MENU (state, items) {
    var i;
    for (i = 0; i < items.length; i++) { 
      state.menus.unshift([i])
    }
  },
  UPDATE_MENU (state, menu) {
    const menuIndex = state.menus.findIndex((p) => p.id === menu.id)
    Object.assign(state.menus[menuIndex], menu)
  },
  REMOVE_MENU (state, Id) {
    const Index = state.menus.findIndex((p) => p.id === Id)
    state.menus.splice(Index, 1)
  },
}

const actions = {
  fetchMenu ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`/menu-list`)
        .then((response) => {
          commit('SET_MENUS', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  addMenu ({ commit }, menu ) {
    return new Promise((resolve, reject) => {
      axios.post('/menu-add', menu)
        .then((response) => {
          commit('ADD_MENU', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateMenu ({ commit }, menu) {
    return new Promise((resolve, reject) => {
      axios.post(`/menu-update/${menu.id}`, menu)
        .then((response) => {
          commit('UPDATE_MENU', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  removeMenu ({ commit }, menu) {
    return new Promise((resolve, reject) => {
      axios.delete(`/menu-delete/${menu.id}`)
        .then((response) => {
          commit('REMOVE_MENU', menu.id)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  }
}

export default {
  isRegistered: false,
  namespaced: true,
  state,
  actions,
  mutations
}